import React from 'react';
import { FormattedMessage } from 'react-intl'

const CookieConsentModal = ({ onClose }) => {

  const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
  );

  const CookieP1 = () => <TranslatedMessage id="CookieP1" />;
  const CookieP2 = () => <TranslatedMessage id="CookieP2" />;
  const CookieP3 = () => <TranslatedMessage id="CookieP3" />;
  const CookieBtn1 = () => <TranslatedMessage id="CookieBtn1" />;
  const CookieBtn2 = () => <TranslatedMessage id="CookieBtn2" />;

  const handleAccept = () => {
    // Set the cookie consent value to 'accepted'
    document.cookie = `cookie_consent=accepted; path=/; max-age=${60 * 60 * 24 * 30}`; // Expires in 30 days
    onClose()
  };

  const handleReject = () => {
    // Set the cookie consent value to 'rejected'
    document.cookie = `cookie_consent=rejected; path=/; max-age=${60 * 60 * 24 * 30}`; // Expires in 30 days
    disableNonEssentialCookies();
    onClose()
  };

  const disableNonEssentialCookies = () => {
    // Disable non-essential cookies here
    // For example, you can set a separate cookie to indicate that non-essential cookies should be disabled
    document.cookie = 'disable_non_essential_cookies=true; path=/; max-age=31536000'; // Expires in 1 year
  };

  return (
    <div className="cookie-consent-modal">
      <p>
        <CookieP1/>{' '}
        <a href="/#/privacy-policy" target="_blank" rel="noopener noreferrer">
        <CookieP2/>
        </a>{' '}
        <CookieP3/>
      </p>
      <button className="btn btn-sm btn-primary me-3" onClick={handleAccept}><CookieBtn1/></button>
      <button className="btn btn-sm btn-primary"  onClick={handleReject}><CookieBtn2/></button>
    </div>
  );
};

export default CookieConsentModal;