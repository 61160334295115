import React, {useEffect, useRef} from 'react'
import YoutubeEmbed from './EmbedVideo'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'
import { ShareButtons } from './ShareButtons'

const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
  );

const MainHeader = () => <TranslatedMessage id="MainHeader" />;
const MainSubHeader = () => <TranslatedMessage id="MainSubHeader" />;
const MainP1 = () => <TranslatedMessage id="MainP1" />
const MainP2 = () => <TranslatedMessage id="MainP2" />
const MainP3 = () => <TranslatedMessage id="MainP3" />
const MainP4 = () => <TranslatedMessage id="MainP4" />
const MainP5 = () => <TranslatedMessage id="MainP5" />
const MainSubHeader2 = () => <TranslatedMessage id="MainSubHeader2" />;
const MainP6 = () => <TranslatedMessage id="MainP6" />
const MainP7 = () => <TranslatedMessage id="MainP7" />
const MainP8 = () => <TranslatedMessage id="MainP8" />
const MainP9 = () => <TranslatedMessage id="MainP9" />
const MainP10 = () => <TranslatedMessage id="MainP10" />
const MainP11 = () => <TranslatedMessage id="MainP11" />
const MainP12 = () => <TranslatedMessage id="MainP12" />
const MainP13 = () => <TranslatedMessage id="MainP13" />
const MainP14 = () => <TranslatedMessage id="MainP14" />
const MainP15 = () => <TranslatedMessage id="MainP15" />
const MainP16 = () => <TranslatedMessage id="MainP16" />
const MainP17 = () => <TranslatedMessage id="MainP17" />
const MainSubHeader3 = () => <TranslatedMessage id="MainSubHeader3" />;
const MainP18 = () => <TranslatedMessage id="MainP18" />
const MainP19 = () => <TranslatedMessage id="MainP19" />
const MainP20 = () => <TranslatedMessage id="MainP20" />
 
const BenefitsMainHeader = () => <TranslatedMessage id="BenefitsMainHeader" />;
const BenefitsHeader = () => <TranslatedMessage id="BenefitsHeader" />;
const BenefitsSubHeader = () => <TranslatedMessage id="BenefitsSubHeader" />;
const BenefitsP1 = () => <TranslatedMessage id="BenefitsP1" />;
const BenefitsP2 = () => <TranslatedMessage id="BenefitsP2" />;
const BenefitsP3 = () => <TranslatedMessage id="BenefitsP3" />;
const BenefitsP4 = () => <TranslatedMessage id="BenefitsP4" />;
const BenefitsLi1 = () => <TranslatedMessage id="BenefitsLi1" />;
const BenefitsLi1o1 = () => <TranslatedMessage id="BenefitsLi1_1" />;
const BenefitsLi1o2 = () => <TranslatedMessage id="BenefitsLi1_2" />;
const BenefitsLi1o3 = () => <TranslatedMessage id="BenefitsLi1_3" />;
const BenefitsLi2 = () => <TranslatedMessage id="BenefitsLi2" />;
const BenefitsLi2o1 = () => <TranslatedMessage id="BenefitsLi2_1" />;
const BenefitsLi2o2 = () => <TranslatedMessage id="BenefitsLi2_2" />;

const UsersMainHeader = () => <TranslatedMessage id="UsersMainHeader" />;
const UsersHeader = () => <TranslatedMessage id="UsersHeader" />;
const UsersSubHeader = () => <TranslatedMessage id="UsersSubHeader" />;
const UsersP1 = () => <TranslatedMessage id="UsersP1" />;
const UsersP2 = () => <TranslatedMessage id="UsersP2" />;
const UsersP3 = () => <TranslatedMessage id="UsersP3" />;
const UsersLi1o1 = () => <TranslatedMessage id="UsersLi1_1" />;
const UsersLi1o2 = () => <TranslatedMessage id="UsersLi1_2" />;
const UsersLi1o3 = () => <TranslatedMessage id="UsersLi1_3" />;

const ResultsMainHeader = () => <TranslatedMessage id="ResultsMainHeader" />;
const ResultsHeader = () => <TranslatedMessage id="ResultsHeader" />;
const ResultsSubHeader = () => <TranslatedMessage id="ResultsSubHeader" />;
const ResultsP1 = () => <TranslatedMessage id="ResultsP1" />;
const ResultsP2 = () => <TranslatedMessage id="ResultsP2" />;
const ResultsLi1o1 = () => <TranslatedMessage id="ResultsLi1_1" />;
const ResultsLi1o2 = () => <TranslatedMessage id="ResultsLi1_2" />;
const ResultsLi1o3 = () => <TranslatedMessage id="ResultsLi1_3" />;
const ResultsLi1o4 = () => <TranslatedMessage id="ResultsLi1_4" />;
const ResultsLi1o5 = () => <TranslatedMessage id="ResultsLi1_5" />;
const ResultsLi1o6 = () => <TranslatedMessage id="ResultsLi1_6" />;

const MethodsMainHeader = () => <TranslatedMessage id="MethodsMainHeader" />;
const MethodsHeader = () => <TranslatedMessage id="MethodsHeader" />;
const MethodsSubHeader = () => <TranslatedMessage id="MethodsSubHeader" />;
const MethodsLi1 = () => <TranslatedMessage id="MethodsLi1" />;
const MethodsLi1o1 = () => <TranslatedMessage id="MethodsLi1_1" />;
const MethodsLi2 = () => <TranslatedMessage id="MethodsLi2" />;
const MethodsLi2o1 = () => <TranslatedMessage id="MethodsLi2_1" />;
const MethodsLi3 = () => <TranslatedMessage id="MethodsLi3" />;
const MethodsLi3o1 = () => <TranslatedMessage id="MethodsLi3_1" />;

const InputsMainHeader = () => <TranslatedMessage id="InputsMainHeader" />;
const InputsHeader = () => <TranslatedMessage id="InputsHeader" />;
const InputsSubHeader = () => <TranslatedMessage id="InputsSubHeader" />;
const InputsLi1 = () => <TranslatedMessage id="InputsLi1" />;
const InputsLi1o1 = () => <TranslatedMessage id="InputsLi1_1" />;
const InputsLi2 = () => <TranslatedMessage id="InputsLi2" />;
const InputsLi2o1 = () => <TranslatedMessage id="InputsLi2_1" />;
const InputsLi3 = () => <TranslatedMessage id="InputsLi3" />;
const InputsLi3o1 = () => <TranslatedMessage id="InputsLi3_1" />;

const FutureMainHeader = () => <TranslatedMessage id="FutureMainHeader" />;
const FutureSubHeader = () => <TranslatedMessage id="FutureSubHeader" />;
const FutureLi1 = () => <TranslatedMessage id="FutureLi1" />;
const FutureLi1o1 = () => <TranslatedMessage id="FutureLi1_1" />;
const FutureLi2 = () => <TranslatedMessage id="FutureLi2" />;
const FutureLi2o1 = () => <TranslatedMessage id="FutureLi2_1" />;
const FutureLi3 = () => <TranslatedMessage id="FutureLi3" />;
const FutureLi3o1 = () => <TranslatedMessage id="FutureLi3_1" />;
const FutureLi4 = () => <TranslatedMessage id="FutureLi4" />;
const FutureLi4o1 = () => <TranslatedMessage id="FutureLi4_1" />;

const Main = () => {
    const intl = useIntl();
    const youtubeRef = messages[intl.locale]['YoutubeEmbed']
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['FeaturesPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['FeaturesPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])

    useEffect(() => {
        const navLinks = document.querySelectorAll(".nav-link")

        navLinks.forEach((navLink) => {
            navLink.classList.remove("active")
        })
    },[])

    return (
        <div className="Feature d-flex justify-content-start" >
            <div className="FeatureContent text-start" >
                <h1 className="fw-semibold"><MainHeader/> </h1>
                <div className="featureImageContainer">
                    <img src="modern-way-of-selecting-a-crane.jpg" alt="general view of crane optimizer app"></img>
                </div> 
                <p className="lead fw-normal" >
                <br/> 
                <MainSubHeader/>
                </p>
                <br/>
                <p className="mt-2" ><MainP1/>
                </p>
                <div className="featureImageContainer">
                    <img src="multiple-crane-location-comparisons.jpg" alt="multiple ways to optimize crane locations"></img>
                </div> 
                <p><MainP2/></p> 
                <p><MainP3/></p>
                <p><MainP4/></p> 
                <p><MainP5/></p>
                <p className="mt-3 lead fw-bolder" >
                <br/> 
                <MainSubHeader2/>
                </p>
                <br/> 
                <p><MainP6/></p>
                <div className="featureImageContainer">
                    <img src="BIM-data-plus-crane-and-construction-database.jpg" alt="BIM and cnostruction data needed for crane selection"></img>
                </div> 
                <p><span className="fw-bolder fst-italic" ><MainP7/></span></p>
                <p><MainP8/></p>
                <p><MainP9/></p>
                <p><MainP10/></p>  
                <p><span className="fw-bolder fst-italic" ><MainP11/></span></p>
                <p><MainP12/></p>
                <p><MainP13/></p>
                <p><span className="fw-bolder fst-italic" ><MainP14/></span></p>
                <p><MainP15/></p>
                <p><MainP16/></p>
                <p><MainP17/></p>
                <div className="featureImageContainer">
                    <img src="visualizing-crane-selection-results.jpg" alt="Visualization of crane selection results"></img>
                </div>
                <p className="mt-3 lead fw-bolder" >
                <br/> 
                <MainSubHeader3/>
                </p>
                <p><MainP18/></p>
                <p className="fw-bold"><MainP20/></p>
                <div className="p-1" ><YoutubeEmbed embedId={youtubeRef} /></div>
                <p><MainP19/></p>
                
                <br/> 
                <ShareButtons/>         
            </div>
        </div>
    )
}

const Benefits = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['BenefitsPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['BenefitsPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
        <div className="Feature d-flex justify-content-start" >
            <div className="FeatureContent text-start" >
                <h1 className="fw-semibold"><BenefitsMainHeader/> </h1> 
                <p className="mt-4 lead fw-normal" >
                <span className="fw-semibold"><BenefitsHeader/> </span> 
                <br/> 
                <BenefitsSubHeader/>
                </p>

                <p className="mt-3" ><BenefitsP1/>
                <span className="fw-bolder fst-italic" > <BenefitsP2/></span>
                </p>
                <div className="featureImageContainer">
                    <img src="crane-optimizer-app-general-view.jpg" alt="general view of crane optimizer app"></img>
                </div>
                <p><BenefitsP3/>
                
                </p>

                <p className="fw-bold" >
                <BenefitsP4/></p>
                <ul>
                    <li className="fw-semibold"><BenefitsLi1/></li>
                    <ul>
                        <li><BenefitsLi1o1/></li>
                        <li><BenefitsLi1o2/></li>
                        <li><BenefitsLi1o3/></li>
                    </ul>
                    <br/>
                    <li className="fw-semibold"><BenefitsLi2/></li>
                    <ul>
                        <li><BenefitsLi2o1/></li>
                        <li><BenefitsLi2o2/></li>
                    </ul>
                </ul>
                <ShareButtons/>
            </div>
            
        </div>
    )
}

const Users = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['UsersPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['UsersPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
        <div className="Feature d-flex" >
            <div className="text-start" >
                <h1 className="fw-semibold"><UsersMainHeader/> </h1>
                <p className="mt-3 lead fw-normal FeatureLead" >
                <span class="fw-semibold"><UsersHeader/></span><br/> <UsersSubHeader/>
                </p>

                <p className="mt-1" ><UsersP1/>
                
                </p>
                <div className="featureImageContainer">
                    <img src="crane-optimizer-crane-from-the-top.jpg" alt="tower crane from above with load charts and radii"></img>
                </div>
                <p><UsersP2/>
                
                </p>

                <p className="fw-bold" ><UsersP3/>
                </p>
                <ul> 
                    <li><UsersLi1o1/></li>
                    <li><UsersLi1o2/></li>
                    <li><UsersLi1o3/></li>
                </ul>
                <ShareButtons/>
            </div>
        </div>
    )
}

const Results = () => {
    const intl = useIntl();
    const youtubeRef = messages[intl.locale]['YoutubeEmbed']
    
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['ResultsPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['ResultsPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }

    },[intl.locale])
    return (
        <div className="Feature" >
            <div className="text-start" >
                <h1 className="fw-semibold"><ResultsMainHeader/> </h1>
                <p className="mt-3 lead fw-normal" >
                <span class="fw-semibold"><ResultsHeader/> </span><br/><ResultsSubHeader/>
                </p>
                <p className="fw-bold"><ResultsP1/>
                
                </p>
                <div className="p-1" ><YoutubeEmbed embedId={youtubeRef} /></div>
                <p className="fw-bold"><ResultsP2/></p>   
                <ul>
                    <li><ResultsLi1o1/></li>
                    <li><ResultsLi1o2/></li>
                    <li><ResultsLi1o3/></li>
                    <li><ResultsLi1o4/></li>
                    <li><ResultsLi1o5/></li>
                    <li><ResultsLi1o6/></li>
                </ul>
                <ShareButtons/>
            </div>
        </div>
    )
}

const Methods = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['MethodsPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['MethodsPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
        <div className="Feature" >
            <div className="text-start" >
                <h1 className="fw-semibold"><MethodsMainHeader/> </h1>
                <p className="mt-3 lead fw-normal" >
                <span class="fw-semibold"><MethodsHeader/> </span><br/><MethodsSubHeader/>
                </p>

                <dl>
                    <dt><MethodsLi1/></dt>
                    <dd><MethodsLi1o1/></dd>
                    <dt><MethodsLi2/></dt>
                    <dd><MethodsLi2o1/></dd>
                    <dt><MethodsLi3/></dt>
                    <dd><MethodsLi3o1/></dd>
                </dl>
                <div className="featureImageContainer">
                    <img src="crane-optimizer-objects-divided-by-weight.jpg" alt="crane weight capacity calculations divided by color"></img>
                </div>
                <ShareButtons/>
            </div>
        </div>
    )
}

const Inputs = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['InputsPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['InputsPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
        <div className="Feature" >
            <div className="text-start" >
                <h1 className="fw-semibold"><InputsMainHeader/> </h1>
                <p className="mt-3 lead fw-normal" >
                <span class="fw-semibold"><InputsHeader/></span>
                <br/>
                <InputsSubHeader/>
                </p>

                <dl>
                    <dt><InputsLi1/></dt>
                    <dd><InputsLi1o1/>
                    </dd>
                    <dt><InputsLi2/></dt>
                    <dd><InputsLi2o1/></dd>
                    <dt><InputsLi3/></dt>
                    <dd><InputsLi3o1/></dd>
                </dl>
                <div className="featureImageContainer">
                    <img src="crane-optimizer-inspecting-object-inside-storey.jpg" alt=" highlighted object weight and distance from crane"></img>
                </div>
                <ShareButtons/>
            </div>
        </div>
    )
}

const Future = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['FuturePageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['FuturePageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
        <div className="Feature" >
            
            <div className="text-start" >
            <h1 className="fw-semibold"><FutureMainHeader/> </h1>
            
                <p className="mt-3 lead fw-normal textBackground" ><FutureSubHeader/>
                </p>

                <dl className="textBackground">
                    <dt><FutureLi1/></dt>
                    <dd><FutureLi1o1/></dd>
                    <dt><FutureLi2/></dt>
                    <dd><FutureLi2o1/></dd>
                    <dt><FutureLi3/></dt>
                    <dd><FutureLi3o1/></dd>
                    <dt><FutureLi4/></dt>
                    <dd><FutureLi4o1/></dd>
                </dl>

            </div>
        </div>
    )
}

export {Main, Benefits, Users, Results, Methods, Inputs, Future }