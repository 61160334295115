import React, {useEffect} from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'

const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);
const PolicyHeader = () => <TranslatedMessage id="PolicyHeader" />
const PolicySubHeader1 = () => <TranslatedMessage id="PolicySubHeader1" />
const PolicySubHeader2 = () => <TranslatedMessage id="PolicySubHeader2" />
const PolicySubHeader3 = () => <TranslatedMessage id="PolicySubHeader3" />
const PolicySubHeader4 = () => <TranslatedMessage id="PolicySubHeader4" />
const PolicySubHeader5 = () => <TranslatedMessage id="PolicySubHeader5" />
const PolicySubHeader6 = () => <TranslatedMessage id="PolicySubHeader6" />
const PolicySubHeader7 = () => <TranslatedMessage id="PolicySubHeader7" />
const PolicySubHeader8 = () => <TranslatedMessage id="PolicySubHeader8" />
const PolicySubHeader9 = () => <TranslatedMessage id="PolicySubHeader9" />
const PolicySubHeader10 = () => <TranslatedMessage id="PolicySubHeader10" />
const PolicySubHeader11 = () => <TranslatedMessage id="PolicySubHeader11" />
const PolicySubHeader12 = () => <TranslatedMessage id="PolicySubHeader12" />
const PolicyP1 = () => <TranslatedMessage id="PolicyP1" />
const PolicyP2 = () => <TranslatedMessage id="PolicyP2" />
const PolicyP3 = () => <TranslatedMessage id="PolicyP3" />
const PolicyP4 = () => <TranslatedMessage id="PolicyP4" />
const PolicyP5 = () => <TranslatedMessage id="PolicyP5" />
const PolicyP6 = () => <TranslatedMessage id="PolicyP6" />
const PolicyP7 = () => <TranslatedMessage id="PolicyP7" />
const PolicyP8 = () => <TranslatedMessage id="PolicyP8" />
const PolicyP9 = () => <TranslatedMessage id="PolicyP9" />
const PolicyP10 = () => <TranslatedMessage id="PolicyP10" />
const PolicyP11 = () => <TranslatedMessage id="PolicyP11" />
const PolicyP12 = () => <TranslatedMessage id="PolicyP12" />

const PrivacyPolicy = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['PolicyPageTitle'];
      document.title = titleText
      
      const metaDescription = messages[locale]['PolicyPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
        <Container className="Contact d-flex flex-column justify-content-center align-items-center mt-4">
            <div class="privacy-policy">
                <h2><PolicyHeader/></h2>
                <br/>
                <h4><PolicySubHeader1/></h4>
                <p><PolicyP1/></p>
                <br/>
                <h4><PolicySubHeader2/></h4>
                <p><PolicyP2/></p>
                <br/>
                <h4><PolicySubHeader3/></h4>
                <p><PolicyP3/></p>
                <br/>
                <h4><PolicySubHeader4/></h4>
                <p><PolicyP4/></p>
                <br/>
                <h4><PolicySubHeader5/></h4>
                <p><PolicyP5/></p>
                <br/>
                <h4><PolicySubHeader6/></h4>
                <p><PolicyP6/></p>
                <br/>
                <h4><PolicySubHeader7/></h4>
                <p><PolicyP7/></p>
                <br/>
                <h4><PolicySubHeader8/></h4>
                <p><PolicyP8/></p>
                <br/>
                <h4><PolicySubHeader9/></h4>
                <p><PolicyP9/></p>
                <br/>
                <h4><PolicySubHeader10/></h4>
                <p><PolicyP10/></p>
                <br/>
                <h4><PolicySubHeader11/></h4>
                <p><PolicyP11/></p>
                <br/>
                <h4><PolicySubHeader12/></h4>
                <p className="mb-5" ><PolicyP12/></p>
                

                
            </div>
        </Container>
    )
}

export default PrivacyPolicy