import React, {useEffect} from 'react'
import { Container, Nav } from 'react-bootstrap'
import { Outlet, NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'

const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);

const FeatureBenefits = () => <TranslatedMessage id="FeatureBenefits" />
const FeatureUsers = () => <TranslatedMessage id="FeatureUsers" />
const FeatureResults = () => <TranslatedMessage id="FeatureResults" />
const FeatureMethods = () => <TranslatedMessage id="FeatureMethods" />
const FeatureInputs = () => <TranslatedMessage id="FeatureInputs" />
const FeatureFuture = () => <TranslatedMessage id="FeatureFuture" />

const Features = () => {

    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['FeaturesPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['FeaturePageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])

    document.body.style.overflowY = 'overlay';
    return (
    <Container className="FeatureContainer d-flex flex-column align-items-center" >
    <div fluid className="d-flex flex-column align-items-center">
        <Nav variant="tabs"  className="d-flex flex-wrap" >
            <Nav.Item>
                <Nav.Link as={NavLink} eventKey="1" to="benefits" ><FeatureBenefits/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} eventKey="2" to="users" ><FeatureUsers/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} eventKey="3" to="results" ><FeatureResults/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} eventKey="4" to="methods" ><FeatureMethods/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} eventKey="5" to="inputs" ><FeatureInputs/></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={NavLink} eventKey="6" to="future" ><FeatureFuture/></Nav.Link>
            </Nav.Item>
        </Nav>
    </div>
        <Outlet/>
    </Container>
    )
}

export { Features as default }