import React, {useEffect} from 'react'
import { Container, Alert } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'

const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);
const NotFoundAlert = () => <TranslatedMessage id="NotFoundAlert" />
const NotFoundP = () => <TranslatedMessage id="NotFoundP" />

const NotFoundPage = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['NotFoundPageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['NotFoundPageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
    <Container>
        <Alert variant="danger">
            <Alert.Heading><NotFoundAlert/></Alert.Heading>
            <p><NotFoundP/></p>
        </Alert>
    </Container>
    )
}

export { NotFoundPage as default }