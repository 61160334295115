import React, {useState, useEffect} from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { Form } from 'react-bootstrap'
import { IntlProvider, FormattedMessage } from 'react-intl'
import { isMobile } from 'react-device-detect'
import HomeMobile from '../Components/HomeMobile';
import ScrollToTop from '../Components/ScrollToTop';
import BSNavbar from '../Components/BSNavbar';
import Home from '../Components/Home';
import Contact from '../Components/Contact'
import Features from '../Components/Features'
import NotFoundPage from '../Components/NotFoundPage';
import PrivacyPolicy from '../Components/PrivacyPolicy';
import SentMessage from '../Components/SentMessage';
import Footer from '../Components/Footer';
import {Main, Benefits, Users, Methods, Inputs, Future, Results } from "../Components/Feature"
import CookieConsentModal from "../Components/CookieModal"

import messages from '../Components/messages';


const AppRouter = () => {
    // Default to English, fallback if browser locale detection fails
    const [locale, setLocale] = useState('en');
    const [showCookieModal, setShowCookieModal] = useState(true);

    useEffect(() => {
        const cookieValue = getCookieValue('cookie_consent');
        setShowCookieModal(!cookieValue);
    }, [])

    const getCookieValue = (name) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith(`${name}=`)) {
            return cookie.substring(name.length + 1);
          }
        }
        return null;
    };

    const handleModalClose = () => {
        setShowCookieModal(false);
      };
    
    const LanguageButtons = () => {
        
        useEffect(() => {
            // Function to get browser locale
            const getBrowserLocale = () => {
                const browserLocale = navigator.language || navigator.userLanguage;
                // console.log("locale: " + browserLocale)
                return browserLocale.split('-')[0]; // Extract language code
            };
  
            // Function to get language preference from cookie
            const getLanguagePreference = () => {
                const langCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('lang='));
                // console.log("langCookie: " + langCookie)
                return langCookie ? langCookie.split('=')[1] : null;
            };
  
            // Determine initial locale based on cookie or browser locale
            const initialLocale = getLanguagePreference() || getBrowserLocale() || 'en';
            setLocale(initialLocale);
        }, []);
  
        // Function to handle language change
        const handleLanguageChange = (newLocale) => {
            setLocale(newLocale);
            // Update cookie with language preference
            document.cookie = `lang=${newLocale};max-age=${60 * 60 * 24 * 30};path=/`; // Cookie expires in 30 days
            console.log("document.cookie: " + document.cookie)
        };
  
        return (
            <div className='languageButtonContainer'>
                <div>
                    <button
                    className={`btn btn-sm ${locale === 'en' ? 'btn-primary' : ''}`}
                    onClick={() => handleLanguageChange('en')}
                    >
                    English
                    </button>
                    <button
                    className={`btn btn-sm ${locale === 'fi' ? 'btn-primary' : ''}`}
                    onClick={() => handleLanguageChange('fi')}
                    >
                    Suomi
                    </button>
                </div>
            </div>
        )
  
    }

    return (
        <>
            
            <HashRouter>
                <IntlProvider locale={locale} messages={messages[locale]}>
                {showCookieModal && <CookieConsentModal onClose={handleModalClose} />}
                    <ScrollToTop />
                    <div className="Header" >
                        <BSNavbar/>
                        <LanguageButtons />
                    </div>
                    <div className="ContentWrapper">
                        <Routes>
                            <Route end path="/" element={isMobile ? <HomeMobile /> : <Home />}/>
                            <Route path="/features" element={<div><Features/><Footer/></div>}>
                                <Route path="*" element={<Main/>} />
                                <Route index element={<Main/>} />
                                <Route path="benefits" element={<Benefits/>} />
                                <Route path="users" element={<Users/>} />
                                <Route path="results" element={<Results/>} />
                                <Route path="methods" element={<Methods/>} />
                                <Route path="inputs" element={<Inputs/>} />
                                <Route path="future" element={<Future/>} />
                            </Route>
                            <Route path="/contact" element={<div><Contact/><Footer/></div>} />
                            <Route path="/privacy-policy" element={<div><PrivacyPolicy/><Footer/></div>} />
                            <Route path="/contact-success" element={<div><SentMessage/><Footer/></div>} />
                            <Route path="*" element={<div><NotFoundPage/><Footer/></div>} />
                        </Routes>
                    </div>
                    {isMobile ? <div></div> : <Footer />}
                </IntlProvider>
        </HashRouter>
    </>
    )
}

export default AppRouter