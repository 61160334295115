const messages = {
    en: {
        CookieP1 : "This website uses cookies to enhance your experience. Please read our",
        CookieP2 : "Privacy Policy",
        CookieP3 : "for more information.",
        CookieBtn1 : "Accept",
        CookieBtn2 : "Reject",

        ShareSocial : "share this page:",
        ShareHomepageSocial : "share this website:",

        YoutubeEmbed : "U4NAopNVft8",

        HomePageTitle : "The Future of Crane Placement and Selection | Crane Optimizer",
        HomePageMeta : "Find the best crane position on your construction site. Filter the right cranes from a database.",
        
        HomePageTitle2 : "Find the Best Construction Crane Spot and Models | Crane Optimizer",
        HomePageMeta2 : "Find the best construction crane positioning and models. BIM and a crane database to optimize crane selection.",

        ContactPageTitle : "Contact Us | Crane Optimizer",
        ContactPageMeta : "Do you need crane planning? Interested in a demo? Want to add your cranes to our system? Send us a message!",

        FeaturesPageTitle : "How to Select a Crane for the Construction Site? | Crane Optimizer",
        FeaturePageMeta : "Find out why you should pay attention to crane selection and how it works.",

        BenefitsPageTitle : "Biggest Benefits of Optimizing Crane Selection | Crane Optimizer",
        BenefitsPageMeta : "Selecting a crane is not only about avoiding mistakes. Save money in your rental, operating and installation costs.",

        UsersPageTitle : "Who Should Use the Crane Selection Tool? | Crane Optimizer",
        UsersPageMeta : "Managing is predicting. This tool is for you, if you prefer not to guess how cranes will work on your project.",

        ResultsPageTitle : "What Are the Results of Crane Optimizing | Crane Optimizer",
        ResultsPageMeta : "Know exactly your crane locations and models that will work. Instant access to results in a web app.",

        MethodsPageTitle : "How Does the Crane Optimization Work | Crane Optimizer",
        MethodsPageMeta : "Safety and security first. Algorithms and smart recipes ensure reliable results.",

        InputsPageTitle : "What Does Crane Selection Require | Crane Optimizer",
        InputsPageMeta : "Put your BIM in good use and watch site management and structural designers work together.",

        FuturePageTitle : "What to Expect Next from Crane Optimizing | Crane Optimizer",
        FuturePageMeta : "Future magic that will make construction site planning even more exciting.",

        NotFoundPageTitle : "Page missing (But Cranes are Not) | Crane Optimizer",
        NotFoundPageMeta : "Click on the navigation bar links to find out more about crane optimization.",

        PolicyPageTitle : "Privacy Policy | Crane Optimizer",
        PolicyPageMeta : "What we do with your data and your rights to it.",

        SentMessagePageTitle : "Thank you for contacting - soon we will optimize! | Crane Optimizer",
        SentMessagePageMeta : "While waiting to be contacted, you can follow us on LinkedIn to stay up to date.",

        navHome: 'Home',
        navFeatures: 'Features',
        navContact: 'Contact',
        ContactHeader1 : 'Interested to find out more?',
        ContactHeader2 : 'Want to give feedback?',
        ContactHeader3 : "Don't hesitate to drop us a message!",
        ContactPlaceholderFirstName : "First name (required)",
        ContactFeedbackFirstName : "Please provide a first name.",
        ContactPlaceholderLastName : "Last name (required)",
        ContactFeedbackLastName : "Please provide a last name.",
        ContactPlaceholderOrg : "Organization (required)",
        ContactFeedbackOrg : "Please provide an organization.",
        ContactPlaceholderMsg : "Message (required)",
        ContactFeedbackMsg : "Please also provide a message.",
        ContactPlaceholderEmail : "Email (required)",
        ContactFeedbackEmail : "Please provide an email address.",
        ContactPlaceholderPhone : "Phone",
        ContactPlaceholderPolicy1 : "I send this form with agreement to the ",
        ContactPlaceholderPolicy2 : "privacy policy",

        MainHeader : "The Modern Way for Selecting a Crane for the Construction Site",
        MainSubHeader : "What makes a good lifting plan? Selecting a crane can be a formidable task, as mistakes are expensive every time. As a mistake happens, it may also leave you wondering: will the same happen again?",
        MainP1 : "The crane essentially sets the requirements for many things happening at the site. From deliveries, storage to even waste management, the crane acts as the heart of a construction site. In fact, the crane is involved in so many construction activities, that in some companies it has been used as a basic metric for project cost estimating as early as in the 1980's. That is how relevant cranes are for the project. Having data of crane usage can give you a good idea of the kind of building project you are dealing with.",
        MainP2 : "It would then seem that good effort should be directed in placing and selecting cranes. This, however, is often not the case. There are several reasons why not.",
        MainP3 : "First of all, calculating manually whether capacity is enough is very laborous even for a single location. Not to mention for hundreds of locations. For this reason, cranes are often selected on the safe side and mistakes accepted as part of the business.",
        MainP4 : "Secondly, the data for calculations is not always available. Structural BIM is still not everyday practice and model reliability continues to be an issue.",
        MainP5 : "Thirdly, any crane-related matters are often left to specialists. The idea is that the rental company knows the best, and if things go wrong, it is easy to blame them. This way of thinking adds costs throughout the crane operating business. Site managers are not challenging the choices given to them, and not tendering for all possible options.",

        MainSubHeader2 : "So then how should the crane selection be done?",
        MainP6 : "To really take full advantage of cranes while avoiding underestimating capacity and reach, we need the following things: the data, the math, and the visualization of results.",
        MainP7 : "The data.",
        MainP8 : "We need to have the weight and distance of each item we want to lift. The weight must include the hoisting gear required and the center of gracity plus a margin for distance. Hoisting gear changes depending on item type and dimensions, for example long slabs may require a steel beam that can take 25% of the total lift weight. The distance needs a margin, as the lift might exceed the distance of the final destination.",
        MainP9 : "Forming this data is not a small task. If you plan to do it with a spreadhseet, be prepared to build a very complicated set of formulas. It might also be an attractive idea to cut corners by limiting lighter elements from the calculations. But to understand fully where we are not using all the available capacity and have a chance of reducing the number of elements, we should also add the lighter elements.",
        MainP10 : "That was the first part of the data. The second part is the crane load chart data for each boom length and angle. Crane manufacturers do not publish their data beyond text documents, so the first thing to do is to collect all the values from those PDFs. For each crane model and type separately.",
        MainP11 : "The math.",
        MainP12 : "The basic idea is to make sure that each item is inside the limits of the crane. Traditionally you would use the load radii to determine this. This is fine, but you do miss some of the capacity between radii. Calculating in this way you are progressing in steps instead of progressing on a curve.",
        MainP13 : "Depending on the crane model, this missed capacity can be significant. The bigger the distance and radius frequency, the bigger the overestimating of needed capacity.",
        MainP14 : "Visualizing the results.",
        MainP15 : "It's nice to have all the calculations accurately done, but not much use if the results need to be interpreted by reading a table of data. We want to show how the item weights and distances are distributed, and which cranes meet our needs.",
        MainP16 : "For this purpose, BIM is our most powerful friend. By transferring the calculated data to the BIM objects, we can use a 3D view to build a graphical representation of our results.",
        MainP17 : "Combined with a crane database table and a plotter chart, we have a dynamic, interactive dashboard for our crane selection process.",

        MainSubHeader3 : "Crane Optimizer gives you a shortcut to the results.",
        MainP18 : "The traditional way of using radii with a floor plan is simple and fast. For a more thorough analysis, there is Crane Optimizer. It is the most advanced tool available for crane selection on a construction site.",
        MainP19 : "No other tool will show you the comparisons between locations, cranes from multiple manufacturers and calculate differences between options. You can compare capacities, distances, costs and see how much excess or deficit a crane has.",
        MainP20 : "This video shows you the powerful features of Crane Optimizer:",

        BenefitsMainHeader : "The Biggest Benefits of Optimizing Crane Selection",
        BenefitsHeader : "The crane is a bottleneck for the whole construction process.",
        BenefitsSubHeader : "Crane Optimizer ensures mistake-free production planning and invites the site management and structural designers to plan together.",
        BenefitsP1 : "When making the site logistics plan, the thing that effects construction flow the most is crane placement. It can also be the most intimidating part, as capacity calculations are complicated and browsing crane models is painstaking. Crane Optimizer makes this job enjoyable from your first draft to your final revision.",
        BenefitsP2 : "Why guess when you can know?",
        BenefitsP3 : "Crane optimization is a low-hanging fruit for the project, but difficult to achieve with traditional methods.",
        BenefitsP4 : "When your lifting plan fails:",
        BenefitsLi1 : "Underestimating crane capacity",
        BenefitsLi1_1 : "Urgent calls for mobile cranes or design changes",
        BenefitsLi1_2 : "Cumulative interference on other management tasks",
        BenefitsLi1_3 : "Health hazards when lifts have to be improvised",
        BenefitsLi2 : "Overestimating crane capacity",
        BenefitsLi2_1 : "increased lease, erecting and operator costs",
        BenefitsLi2_2 : "extra time and energy spent on lifts and installation",

        UsersMainHeader : "Who Should Use the Crane Selection Tool?",
        UsersHeader : "Managing is predicting.",
        UsersSubHeader : "Crane Optimizer is for those who want to manage their construction site, and not only react when things fail.",
        UsersP1 : "The current version of Crane Optimizer works best in a project with a plan to install a tower crane (high or flat top). It can nevertheless be used in all projects with different types of cranes.",
        UsersP2 : "Even with simpler designs, where optimization may not give significant improvement, this tool can be used for validating and revisioning your lifting plans, filtering the suitable crane models and optimizing your structural design.",
        UsersP3 : "Main advantages:",
        UsersLi1_1 : "Know how much lifting power you really need and where",
        UsersLi1_2 : "See where you should increase or decrease lift weights",
        UsersLi1_3 : "Know which crane models are optimal for your project",

        ResultsMainHeader : "What Are the Results of Crane Optimizing",
        ResultsHeader : "Project results are published in a secure web application.",
        ResultsSubHeader : "Easy access with the browser and intuitive app design means analyzing the results is fast.",
        ResultsP1 : "This quick video shows the dashboard in live action:",
        ResultsP2 : "Main features of the app:",
        ResultsLi1_1 : "Rank locations with four different optimization methods",
        ResultsLi1_2 : "Compare and filter locations",
        ResultsLi1_3 : "Compare and filter crane models and costs from a database",
        ResultsLi1_4 : "Inspect how much capacity is exceeded or unused per object",
        ResultsLi1_5 : "Inspect manufacturer load charts in 3D and 2D",
        ResultsLi1_6 : "Built-in instructions and videos",

        MethodsMainHeader : "How Does the Crane Optimization Work",
        MethodsHeader : "The main priority is to estimate securely.",
        MethodsSubHeader : "Smart recipes ensure correct weight quantification. Algorithms calculate necessary material loads from BIM and iterate each item to be lifted.",
        MethodsLi1 : "Recipes",
        MethodsLi1_1 : "Parametric recipes calculate the weights and the hoisting equipment according to object dimensions. The recipes can take into account assemblies, lifting and storage locations, material packages, equipment and anything else that needs lifting. All modelled to be part of the site and logistics BIM.",
        MethodsLi2 : "Algorithm",
        MethodsLi2_1 : "The optimization algorithm calculates each combination by capacity, distance, time and overall. These four metrics can be then used to determine the best locations for cranes.",
        MethodsLi3 : "Automated materials",
        MethodsLi3_1 : "Material packages can be created automatically for each floor based on the building information model's quantities. This way the material packages to be lifted during the structural phase are also considered when necessary. This is done using the Autodesk Revit API.",
    
        InputsMainHeader : "What Does Crane Selection Require",
        InputsHeader : "Crane Optimizer makes sure that site management and structural engineers are on top of their jobs.",
        InputsSubHeader : "The inputs for the optimization push your team to plan ahead the construction methods and design, contributing to better project management.",
        InputsLi1 : "BIM",
        InputsLi1_1 : "As the optimizing is based on a building information model, a detailed enough model is needed. But not to worry - support is available, and modelling service and inspection can be included in the package!",
        InputsLi2 : "Lift items",
        InputsLi2_1 : "The scope of lifts will be verified in a kick-off meeting. If the project is split to multiple phases, the lifts can be divided to several divisions.",
        InputsLi3 : "Site plan",
        InputsLi3_1 : "Schematics of crane installation and material offloading areas are not compulsory but may help to streamline the results. DWG, IFC, PDF, pen and paper - the choice of format is yours! The rest is modelled for you.",
    
        FutureMainHeader : "What to Expect Next from Crane Optimizing",
        FutureSubHeader : "Crane Optimizer improves continuously. Future magic will include:",
        FutureLi1 : "Multiple cranes",
        FutureLi1_1 : "To further optimize, we have the algorithm consider multiple locations. With relevant constructability constraints, the results will show the best division of labor between the cranes.",
        FutureLi2 : "Carbon footprint estimator",
        FutureLi2_1 : "See the environmental effect of your options.",
        FutureLi3 : "Rental costs and tendering",
        FutureLi3_1 : "Get current cost information for cranes with the possibility to send tenders to rental companies.",
        FutureLi4 : "4D phasing simulation with crane usage",
        FutureLi4_1 : "We already do a lot of heavy lifting when optimizing. By connecting our recipes with installation times and installation order, we can create a lifting order to further boost our crane usage.",

        FeatureBenefits : "Why Crane Optimizer?",
        FeatureUsers : "Who is it for?",
        FeatureResults : "What you get?",
        FeatureMethods : "How it is done?",
        FeatureInputs : "What is needed?",
        FeatureFuture : "Coming soon",

        demoP1 : "Interested in a ",
        demoP2 : "demo",
        demoP3 : "Contact us and you can book a time that suits your team.",

        Slide1Header1 : "Introducing ",
        Slide1Header2 : "Crane Optimizer",
        Slide1P1 : "One-of-a-kind tool for crane placement and selection. ",
        Slide1P2 : "Optimized with the use of BIM.",

        Slide2Header1 : "Find the ",
        Slide2Header2 : "best locations ",
        Slide2Header3 : "for cranes.",
        Slide2P1 : "See the exact capacity and reach needed for each location.",
        Slide2Li1 : "Avoid costly placement mistakes",
        Slide2Li2 : "See which lifts effect cranes the most",
        Slide2Li3 : "Update your lifting plan whenever needed",

        Slide3Header1 : "Compare ",
        Slide3Header2 : "multiple locations ",
        Slide3Header3 : "with each others.",
        Slide3P1 : "Find out the differences between your options.",
        Slide3Li1 : "Optimize your site plan",
        Slide3Li2 : "See the consequences of changing locations",

        Slide4Header1 : "Find the ",
        Slide4Header2 : "right cranes ",
        Slide4Header3 : "for your project.",
        Slide4P1 : "Filter suitable models from an extensive crane database.",
        Slide4Li1 : "Save money by tendering all models",
        Slide4Li2 : "Compare costs between options",
        Slide4Li3 : "Find which models meet your criteria",

        Slide5Header1 : "Optimize your ",
        Slide5Header2 : "structural design",
        Slide5P1 : "Make use of all available capacity without exceeding it.",
        Slide5Li1 : "Reduce the number of prefabs and lifts",
        Slide5Li2 : "See the crane limits to avoid extra costs",
        Slide5Li3 : "Communicate boundaries to your team",

        Slide6Header1 : "Visualize ",
        Slide6Header2 : "manufacturer load charts",
        Slide6P1 : "Show the cranes in 3D and 2D.",
        Slide6Li1 : "Find crane information instantly",
        Slide6Li2 : "Communicate in a highly visual fashion",
        Slide6Li3 : "Inspect crane manufacturer load charts",

        Slide7Header1 : "Intuitive and powerful ",
        Slide7Header2 : "BIM interface",
        Slide7P1 : "Get inside your model in the browser.",
        Slide7Li1 : "Web-based application for easy access",
        Slide7Li2 : "Only the buttons and switches you need",
        Slide7Li3 : "Instructions and videos built into the app",

        NotFoundAlert : "404 - Page not found.",
        NotFoundP : "Use links in the navigation bar to access valid pages.",

        PolicyHeader : "Privacy Policy",
        PolicySubHeader1 : "What data do we collect and process?",
        PolicySubHeader2 : "How is your data shared?",
        PolicySubHeader3 : "How is your data secured?",
        PolicySubHeader4 : "How is your data stored?",
        PolicySubHeader5 : "How long is your data retained?",
        PolicySubHeader6 : "What are your data protection rights?",
        PolicySubHeader7 : "Marketing",
        PolicySubHeader8 : "Legal Basis for Processing",
        PolicySubHeader9 : "Complaint Process",
        PolicySubHeader10 : "Updates to the Privacy Policy",
        PolicySubHeader11 : "GDPR and CCPA Compliance",
        PolicySubHeader12 : "Date and contact",
        PolicyP1 : "We collect data through the contact form and may also collect data automatically through cookies and similar technologies.",
        PolicyP2 : "We do not share your data with third parties except for the email provider used for sending automated emails, and we ensure they comply with data protection regulations.",
        PolicyP3 : "We implement appropriate technical and organizational measures to safeguard your data against unauthorized access, alteration, disclosure, or destruction.",
        PolicyP4 : "Your data may be transferred to and stored on servers located outside of your country or jurisdiction. By submitting your data, you consent to this transfer.",
        PolicyP5 : "We retain your data for as long as necessary to fulfill the purposes outlined in this policy or as required by law. Data will be securely deleted after the retention period.",
        PolicyP6 : "You have full rights to your data. At your request, we will send, rectify, restrict processing or delete your data.",
        PolicyP7 : "Based on your contact info, we will offer you what we see as best fit services to meet your needs. No other unsolicited mail will be sent to you as a result of giving data to us.",
        PolicyP8 : "We process your data based on your consent when you submit it through the contact form or when it is necessary for the performance of a contract.",
        PolicyP9 : "If you have concerns about how we handle your data, please contact us. You also have the right to lodge a complaint with a supervisory authority.",
        PolicyP10 : "We may update this privacy policy from time to time. Any changes will be effective immediately upon posting the revised policy on this page.",
        PolicyP11 : "This privacy policy complies with the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA) where applicable.",
        PolicyP12 : "This policy was last updated on 8.5.2024. If you wish to contact us regarding this policy, please use the contact form or the email address presented in the web page.",

        SentMessageHeader : "Thank you for your message!",
        SentMessageP : "You will be contacted shortly."

    },
    fi: {
        CookieP1 : "Tämä sivusto käyttää evästeitä parempaa käyttökokemusta varten. Lue",
        CookieP2 : "Tietosuojakäytäntömme",
        CookieP3 : "saadaksesi lisätietoa.",
        CookieBtn1 : "Hyväksy",
        CookieBtn2 : "Hylkää",

        ShareSocial : "jaa tämä sivu:",
        ShareHomepageSocial : "jaa tämä sivusto:",

        YoutubeEmbed : "HdVdlb_Cv2o",

        HomePageTitle : "Löydä työmaasi nosturin paras sijainti ja malli | Crane Optimizer",
        HomePageMeta : "Uusi tapa optimoida nosturivalinta työmaalle. Valitse paras sijainti ja suodata sopivat nosturit tietokannasta.",
        
        HomePageTitle2 : "Nosturin sijoituksen ja valinnan tulevaisuus | Crane Optimizer",
        HomePageMeta2 : "",

        ContactPageTitle : "Ota yhteyttä | Crane Optimizer",
        ContactPageMeta : "Tarvitsetko nostosuunnittelua? Haluatko varata demon? Lähetä meille viesti!",

        FeaturesPageTitle : "Kuinka valitset nosturin työmaalle? | Crane Optimizer",
        FeaturePageMeta : "Ota selvää, miksi nosturivalintaan tulee kiinnittää huomiota ja miten se toimii.",

        BenefitsPageTitle : "Nosturioptimoinnin suurimmat edut | Crane Optimizer",
        BenefitsPageMeta : "Nosturivalinnassa on kyse muustakin kuin virheiden välttämisestä. Näin voit säästää vuokra- kuljettaja- ja asennuskuluissa.",

        UsersPageTitle : "Kenen kannattaa käyttää nosturioptimointia? | Crane Optimizer",
        UsersPageMeta : "Johtaminen on ennakointia. Tämä työkalu on sinulle, joka et halua arvailla riittävätkö työmaasi nosturit.",

        ResultsPageTitle : "Mitkä ovat nosturioptimoinnin tulokset | Crane Optimizer",
        ResultsPageMeta : "Tiedä tarkalleen parhaat sijainnit ja sopivat nosturimallit. Nopea pääsy tuloksiin verkkosovelluksessa.",

        MethodsPageTitle : "Miten nosturioptimointi toimii | Crane Optimizer",
        MethodsPageMeta : "Turvallisuus ensin. Algoritmit ja älykkäät reseptit varmistavat luotettavat tulokset.",

        InputsPageTitle : "Mitä nosturin valinta vaatii | Crane Optimizer",
        InputsPageMeta : "Hyödynnä tietomallejasi, niin työnjohto ja rakennesuunnittelu toimivat yhteistyössä.",

        FuturePageTitle : "Mitä odottaa nosturioptimoinnilta seuraavaksi | Crane Optimizer",
        FuturePageMeta : "Tulevaisuuden taikatemput tekevät työmaan suunnittelusta vielä palkitsevampaa.",

        NotFoundPageTitle : "Sivu hukassa (mutta nosturit eivät) | Crane Optimizer",
        NotFoundPageMeta : "Klikkaa navigointipalkin linkkejä saadaksesi lisätietoa nosturioptimoinnista.",

        PolicyPageTitle : "Tietosuojakäytäntö | Crane Optimizer",
        PolicyPageMeta : "Mitä teemme tiedoillasi ja sinun oikeutesi niihin.",     
        
        SentMessagePageTitle : "Kiitos viestistä - kohta optimoidaan! | Crane Optimizer",
        SentMessagePageMeta : "Vastausta odotellessasi voit seurata meitä LinkedIn:ssä pysyäksesi ajan tasalla.",

        navHome: 'Etusivu',
        navFeatures: 'Ominaisuudet',
        navContact: 'Ota yhteyttä',
        ContactHeader1 : 'Oletko kiinnostunut saamaan lisätietoa?',
        ContactHeader2 : 'Haluatko antaa palautetta?',
        ContactHeader3 : 'Älä epäröi, vaan lähetä viestiä!',
        ContactPlaceholderFirstName : "Etunimi (pakollinen)",
        ContactFeedbackFirstName : "Ole hyvä ja täytä etunimesi.",
        ContactPlaceholderLastName : "Sukunimi (pakollinen)",
        ContactFeedbackLastName : "Ole hyvä ja täytä sukunimesi.",
        ContactPlaceholderOrg : "Organisaatio (pakollinen)",
        ContactFeedbackOrg : "Ole hyvä ja täytä organisaatiosi.",
        ContactPlaceholderMsg : "Viesti (pakollinen)",
        ContactFeedbackMsg : "Ole hyvä ja täytä myös viestikenttä.",
        ContactPlaceholderEmail : "Sähköposti (pakollinen)",
        ContactFeedbackEmail : "Ole hyvä ja anna sähköpostiosoite.",
        ContactPlaceholderPhone : "Puhelinnumero",
        ContactPlaceholderPolicy1 : "Hyväksyn ",
        ContactPlaceholderPolicy2 : "tietosuojakäytännön",

        MainHeader : "Nykyaikainen tapa valita nosturi rakennustyömaalle",
        MainSubHeader : "Mikä tekee hyvän nostosuunnitelman? Nosturin valinta voi olla pelottava tehtävä, sillä virheet ovat kalliita joka kerta. Virheen sattuessa saatat myös jäädä pohtimaan: tapahtuuko sama uudelleen?",
        MainP1 : "Nosturi asettaa vaatimukset monille työmaalla tapahtuville asioille. Toimituksista varastointiin ja jopa jätehuoltoon, nosturi toimii rakennustyömaan sydämenä. Itse asiassa nosturi osallistuu niin moneen rakentamisen tehtävään, että joissain yrityksissä sitä alettiin käyttämään mittarina projektin kustannusarvioinnissa jo 1980-luvulla. Se kertoo, kuinka olennainen rooli nostureilla on projektissa. Nostureiden käyttötietojen saaminen voi antaa hyvän käsityksen siitä, millaisesta rakennushankkeesta on kyse.",
        MainP2 : "Voisi siis ajatella, että nostureiden sijoittamiseen ja valintaan nähtäisiin paljon vaivaa. Näin ei kuitenkaan usein ole. On monia syitä, miksi ei.",
        MainP3 : "Ensinnäkin kapasiteetin riittävyyden laskeminen manuaalisesti on hyvin työlästä jopa yhdelle sijainnille. Puhumattakaan sadoille eri sijainneille. Tästä syystä nosturit valitaan usein varman päälle, ja virheet hyväksytään osana toimintaa.",
        MainP4 : "Toisekseen laskentadataa ei aina ole saatavilla. Rakennemalli ei välttämättä ole tarpeeksi tarkka, ja mallin luotettavuus voi olla ongelma.",
        MainP5 : "Kolmanneksi nostokalustoon liittyvät asiat jätetään usein kalustoasiantuntijoille. Ajatuksena on, että kalustovuokraaja tuntee asiat parhaiten, ja jos asiat menevät pieleen, heitä on myös helppo syyttää. Tämä ajattelutapa lisää kustannuksia kalustovuokrauksessa. Työnjohtajat eivät haasta heille annettuja valintoja, eivätkä pyydä tarjouksia kaikista mahdollisista vaihtoehdoista.",
        MainSubHeader2 : "Kuinka nostosuunnittelu sitten tulisi tehdä?",
        MainP6 : "Jotta voimme hyödyntää nostureita täysimääräisesti ilman kapasiteetin ja ulottuvuuden ylityksiä, tarvitsemme seuraavat asiat: datan, laskenta-algoritmit ja tulosten visualisoinnin.",
        MainP7 : "Data.",
        MainP8 : "Meidän on tiedettävä jokaisen nostettavan kokonaisuuden yhteispaino ja etäisyys. Painoon on sisällyttävä tarvittava nostovarustelu ja etäisyydelle painopisteen lisäksi marginaali. Nostovarustelu vaihtelee elementin tyypin ja mittojen mukaan, esimerkiksi pitkät laatat voivat vaatia teräspalkin, joka voi kattaa 25 % kokonaispainosta. Etäisyydelle tarvitaan marginaali, koska vaakasiirto voi ylittää elementin lopullisen sijainnin.",
        MainP9 : "Tämän datan muodostaminen ei ole helppo työ. Jos aikoo tehdä sen taulukkolaskentaohjelmalla, pitää varautua tekemään monimutkaiset laskentakaavat. Saattaa myös tuntua houkuttelevalta oikotieltä rajata kevyimmät elementit laskennan ulkopuolelle. Mutta tietääksemme, missä emme käytä kaikkea käytettävissä olevaa kapasiteettia ja sitä kautta saada mahdollisuuden vähentää elementtien lukumäärää, meidän tulisi laskea mukaan myös kevyimmät elementit.",
        MainP10 : "Tämä on datan ensimmäinen osuus. Toinen osuus on nosturin kapasiteettitiedot jokaiselle puomin pituudelle ja nostokulmalle. Nosturinvalmistajat eivät julkaise tietojaan suoraan taulukkomuodossa, joten ensimmäinen tehtävä on kerätä kaikki arvot PDF-tiedostoista. Jokaiselle nosturimallille ja -tyypille erikseen.",
        MainP11 : "Laskenta-algoritmit.",
        MainP12 : "Perusajatus on varmistaa, että jokainen nostettava kokonaisuus on nostokyvyn rajoissa. Perinteisesti tämän määrittämiseen käytetään nostosäteitä. Tämä on kelvollinen tapa, mutta sillä menetetään osa kapasiteetista säteiden välistä. Laskemalla tällä tavalla edetään kapasiteettilaskennassa portaittain, tarkemman nostokäyrän sijaan.",
        MainP13 : "Nosturimallista riippuen tämä menetetty kapasiteetti voi olla merkittävä. Mitä pidempi puomi ja mitä harvemmin nostoarvoja, sitä enemmän jää kapasiteettia käyttämättä.",
        MainP14 : "Tulosten visualisointi.",
        MainP15 : "On mukavaa, että kaikki laskelmat tehdään tarkasti, mutta tuloksista ei ole paljoakaan hyötyä, jos ne täytyy tulkita taulukkoa selaamalla. Meidän pitää näyttää, kuinka kohteiden painot ja etäisyydet jakautuvat, ja mitkä nosturit vastaavat tarpeitamme.",
        MainP16 : "Tässä vaiheessa tietomalli on paras ystävämme. Siirtämällä laskentatulokset tietomallin objekteille, voimme hyödyntää 3D-näkymää rakentaaksemme graafisen esityksen tuloksistamme.",
        MainP17 : "Yhdistettynä nosturitietokantaan ja pistekaavioon meillä on dynaaminen, interaktiivinen työkalu nosturin valintaprosessia varten.",
        MainSubHeader3 : "Crane Optimizer antaa valmiit tulokset",
        MainP18 : "Perinteinen tapa käyttää nostosäteitä aluesuunnitelman tai tietomallin kanssa on yksinkertainen ja nopea. Crane Optimizerilla saat tarkemmat ja laajemmat tulokset. Se on markkinoiden edistynein työkalu rakennustyömaan nostosuunnitteluun.",
        MainP19 : "Mikään muu työkalu ei näytä sinulle vertailuja eri sijaintien kesken, nostureita useilta valmistajilta ja laske niille kapasiteettierotuksia. Voit verrata kapasiteetteja, etäisyyksiä, kustannuksia sekä nähdä, kuinka paljon valintasi on yli tai alle nosturin nostokyvyn.",
        MainP20 : "Tämä video näyttää sinulle Crane Optimizerin tehokkaat ominaisuudet:",


        BenefitsMainHeader : "Mitkä ovat nosturioptimoinnin suurimmat edut?",
        BenefitsHeader : "Nosturi tahdistaa koko rakennusprosessia.",
        BenefitsSubHeader : "Nosturioptimointi varmistaa virheettömän tuotantosuunnittelun ja tuo työnjohdon ja rakennesuunnittelijat saman pöydän ääreen.",
        BenefitsP1 : "Työmaan logistiikkaa suunniteltaessa, asia, joka vaikuttaa eniten rakentamisen sujuvuuteen, on nosturin sijainti. Se voi myös olla suunnittelun tuskastuttavin vaihe, koska kapasiteettilaskelmat ovat monimutkaisia ja nosturimallien selailu vaivalloista. Crane Optimizer tekee tästä työstä hauskaa ensimmäisestä luonnoksesta viimeiseen revisioon.",
        BenefitsP2 : "Miksi jäädä arvailemaan, kun voi tietää?",
        BenefitsP3 : "Nosturioptimointi on projektille matalalla roikkuva hedelmä, mutta vaikea saavuttaa perinteisin menetelmin.",
        BenefitsP4 : "Kun nostosuunnitelmasi epäonnistuu:",
        BenefitsLi1 : "Nosturin kapasiteetin aliarviointi",
        BenefitsLi1_1 : "Kiireellinen tarve mobiilinostureille tai suunnitelmamuutoksille",
        BenefitsLi1_2 : "Ongelmien kertaantuminen muualle työnjohtoon",
        BenefitsLi1_3 : "Työturvallisuusriskit, kun nostoja ryhdytään improvisoimaan",
        BenefitsLi2 : "Nosturin kapasiteetin yliarviointi",
        BenefitsLi2_1 : "Vuokra-, asennus- ja kuljettajakustannusten kasvu",
        BenefitsLi2_2 : "Nostoihin ja asennuksiin kuluvan ajan kasvu",

        UsersMainHeader : "Kenen kannattaa käyttää nosturin valintatyökalua?",
        UsersHeader : "Johtaminen on ennakointia.",
        UsersSubHeader : "Crane Optimizer on niille, jotka haluavat johtaa rakennustyömaataan, eivätkä vain reagoida ongelmiin niiden ilmetessä.",
        UsersP1 : "Nykyinen Crane Optimizerin versio toimii parhaiten projektissa, jossa on tarkoitus asentaa torninosturi (huipullinen tai huiputon). Sitä voidaan kuitenkin hyödyntää kaikissa projekteissa erityyppisille nostureille.",
        UsersP2 : "Myös yksinkertaisemmissa runkomuodoissa, joissa optimointi ei välttämättä tuo merkittävää hyötyä, tätä työkalua voidaan käyttää nostosuunnitelmiesi varmistamiseen, sopivien nosturimallien suodattamiseen ja elementtisuunnittelun optimointiin.",
        UsersP3 : "Päähyödyt:",
        UsersLi1_1 : "Tiedä kuinka paljon nostotehoa tarvitset ja missä",
        UsersLi1_2 : "Näe missä sinun pitäisi vähentää tai missä voit lisätä nostopainoja",
        UsersLi1_3 : "Tiedä, mitkä nosturimallit ovat ihanteellisia sinun projektillesi",

        ResultsMainHeader : "Mitkä ovat nosturioptimoinnin tulokset?",
        ResultsHeader : "Projektin tulokset julkaistaan suojatussa verkkosovelluksessa.",
        ResultsSubHeader : "Helppo pääsy selaimella ja intuitiivinen sovellus tarkoittaa, että tulosten analysointi on nopeaa.",
        ResultsP1 : "Alla oleva lyhyt video näyttää sovelluksen käytännössä:",
        ResultsP2 : "Tärkeimmät ominaisuudet:",
        ResultsLi1_1 : "Optimoi sijainti neljällä eri mittarilla",
        ResultsLi1_2 : "Vertaile ja suodata sijainteja",
        ResultsLi1_3 : "Vertaile ja suodata nosturimalleja ja kustannuksia tietokannasta",
        ResultsLi1_4 : "Tarkista, kuinka paljon kapasiteettia ylittyy tai jää käyttämättä",
        ResultsLi1_5 : "Tarkistele nosturivalmistajan kuorma-arvoja 3D- ja 2D-näkymissä",
        ResultsLi1_6 : "Käyttötapauskohtaiset ohjeet ja videot sovelluksen sisällä",

        MethodsMainHeader : "Kuinka nosturioptimointi toimii?",
        MethodsHeader : "Tärkeintä on arvioida turvallisesti.",
        MethodsSubHeader : "Älykkäät reseptit varmistavat oikean painon määrityksen. Algoritmit laskevat tarvittavat materiaalikuormat tietomallista ja iteroivat jokaisen sijainnin.",
        MethodsLi1 : "Reseptit",
        MethodsLi1_1 : "Parametriset reseptit laskevat painot ja nostovarusteet elementtityypin ja -koon mukaan. Reseptit huomioivat tarvittaessa eri kokoonpanot, nosto- ja varastointipaikat, materiaalipakkaukset, koneet, laitteet ja kaiken muun, mikä vaatii nosturia. Kaikki mallinnetaan osaksi tietomallipohjaista aluesuunnitelmaa.",
        MethodsLi2 : "Algoritmi",
        MethodsLi2_1 : "Optimointialgoritmi laskee jokaisen yhdistelmän kapasiteetin, etäisyyden, ajan ja kokonaisuuden mukaan. Näitä neljää mittaria voidaan sen jälkeen käyttää parhaiden nosturisijaintien määrittämiseen.",
        MethodsLi3 : "Automatisoidut materiaalimäärät",
        MethodsLi3_1 : "Materiaalipakkaukset voidaan luoda automaattisesti jokaiselle kerrokselle perustuen tietomallien määriin. Näin runkovaiheen aikana nostettavat materiaalipaketit voidaan myös ottaa tarvittaessa huomioon.",

        InputsMainHeader : "Mitä nosturin valinta vaatii?",
        InputsHeader : "Crane Optimizer varmistaa, että työnjohto ja rakennesuunnittelijat ovat työnsä tasalla.",
        InputsSubHeader : "Optimoinnin lähtötiedot kannustavat tiimiäsi miettimään rakentamismenetelmät ja runkoratkaisut hyvissä ajoin, edistäen laadukkaampaa projektinjohtoa.",
        InputsLi1 : "BIM",
        InputsLi1_1 : "Koska optimointi perustuu rakennemalliin, tarvitaan tietomalli, jossa elementtijakoa on tehty. Mutta älä huoli - tarvittaessa mallintamista ja tarkastusta voidaan sisällyttää pakettiin!",
        InputsLi2 : "Nostettavat osat",
        InputsLi2_1 : "Nostojen laajuus varmistetaan aloituskokouksessa. Jos projekti jaetaan useisiin vaiheisiin, optimointi voidaan myös jakaa useampaan osaan.",
        InputsLi3 : "Aluesuunnitelma",
        InputsLi3_1 : "Nosturin asennus- ja materiaalien purkualueiden määritykset eivät ole pakollisia, mutta voivat auttaa tehostamaan tuloksia. DWG, IFC, PDF, kynä ja paperi - mikä vain käy! Loput mallinnetaan puolestasi.",

        FutureMainHeader : "Mitä odottaa nosturioptimoinnilta seuraavaksi",
        FutureSubHeader : "Crane Optimizer kehittyy jatkuvasti. Tulevaisuuden taikoja ovat muun muassa:",
        FutureLi1 : "Useampi nosturi",
        FutureLi1_1 : "Seuraava askel optimoinnissa on ottaa huomioon useita sijainteja. Rakennettavuuteen liittyvät rajoitteet huomioiden, tulokset näyttävät parhaan nostojaon nostureiden kesken.",
        FutureLi2 : "Hiilijalanjäljen arviointi",
        FutureLi2_1 : "Näe vaihtoehtojesi ympäristövaikutus.",
        FutureLi3 : "Vuokrahinnat ja tarjouspyynnöt",
        FutureLi3_1 : "Hanki ajankohtaista hintatietoa nostureista ja lähetä tarjouspyynnöt kalustovuokraajille.",
        FutureLi4 : "4D-vaiheistussimulaatio nosturien käytöllä",
        FutureLi4_1 : "Tuotamme jo paljon dataa nosturioptimoinnissa. Yhdistämällä reseptimme asennusaikoihin ja asennusjärjestykseen voimme luoda nostosuunnitelman, joka edelleen tehostaa nosturien käyttöä.",

        FeatureBenefits : "Miksi Crane Optimizer?",
        FeatureUsers : "Kenelle se on?",
        FeatureResults : "Mitä saat?",
        FeatureMethods : "Kuinka se toimii?",
        FeatureInputs : "Mitä tarvitaan?",
        FeatureFuture : "Tulossa seuraavaksi",

        demoP1 : "Oletko kiinnostunut ",
        demoP2 : "demosta",
        demoP3 : "Ota yhteyttä ja varaa tiimillesi sopiva aika.",

        Slide1Header1 : " ",
        Slide1Header2 : "Crane Optimizer",
        Slide1P1 : "Ainutlaatuinen työkalu nosturin sijoitteluun ja valintaan.",
        Slide1P2 : "Optimoitu BIM:in avulla.",

        Slide2Header1 : "Löydä ",
        Slide2Header2 : "parhaat sijainnit ",
        Slide2Header3 : "nostureille.",
        Slide2P1 : "Näe tarkka kapasiteetti ja ulottuvuus jokaiselle sijainnille.",
        Slide2Li1 : "Vältä kalliit nosturin sijoitteluvirheet",
        Slide2Li2 : "Näe, mitkä elementit vaikuttavat eniten nostureihin",
        Slide2Li3 : "Päivitä nostosuunnitelmasi aina tarpeen vaatiessa",

        Slide3Header1 : "Vertaa ",
        Slide3Header2 : "useita sijainteja ",
        Slide3Header3 : "keskenään.",
        Slide3P1 : "Näe kuinka paljon vaihtoehtosi eroavat toisistaan.",
        Slide3Li1 : "Optimoi työmaan aluesuunnitelma",
        Slide3Li2 : "Näe mitä sijainnin muutos aiheuttaa",

        Slide4Header1 : "Löydä ",
        Slide4Header2 : "oikeat nosturit ",
        Slide4Header3 : "projektillesi.",
        Slide4P1 : "Löydä sopivat nosturimallit laajasta tietokannasta.",
        Slide4Li1 : "Säästä rahaa kilpailuttamalla kaikki vaihtoehdot",
        Slide4Li2 : "Vertaa vaihtoehtojen kokonaiskustannuksia",
        Slide4Li3 : "Löydä kriteerisi täyttävät nosturit",

        Slide5Header1 : "Optimoi ",
        Slide5Header2 : "elementtirunkosi",
        Slide5P1 : "Käytä kaikki saatavilla oleva kapasiteetti ylittämättä sitä.",
        Slide5Li1 : "Vähennä elementtien ja nostojen lukumäärää",
        Slide5Li2 : "Näe nosturin rajat välttääksesi ylimääräisiä nosturikuluja",
        Slide5Li3 : "Kommunikoi nostokyvyn rajat tiimillesi",

        Slide6Header1 : "Visualisoi ",
        Slide6Header2 : "valmistajan nostoarvot",
        Slide6P1 : "Näytä nosturit 3D:nä ja 2D:nä.",
        Slide6Li1 : "Löydä nostureiden tiedot välittömästi",
        Slide6Li2 : "Kommunikoi suunnitelmasi visuaalisella tavalla",
        Slide6Li3 : "Tarkastele valmistajan nostoarvoja",

        Slide7Header1 : "Intuitiivinen suomenkielinen ",
        Slide7Header2 : "BIM-käyttöliittymä",
        Slide7P1 : "Pääse tietomalliisi käsiksi suoraan selaimessa.",
        Slide7Li1 : "Selainpohjainen sovellus helposti käytössäsi",
        Slide7Li2 : "Vain ne napit ja kytkimet joita tarvitset",
        Slide7Li3 : "Ohjeet ja videot suoraan sovelluksessa",

        NotFoundAlert : "404 - Sivua ei löydy.",
        NotFoundP : "Käytä navigointipalkin linkkejä siirtyäksesi sivulle, jolla on sisältöä.",

        PolicyHeader : "Tietosuojakäytäntö",
        PolicySubHeader1 : "Mitä tietoja keräämme ja käsittelemme?",
        PolicySubHeader2 : "Miten tietojasi jaetaan?",
        PolicySubHeader3 : "Miten tietojasi turvataan?",
        PolicySubHeader4 : "Miten tietojasi tallennetaan?",
        PolicySubHeader5 : "Kuinka kauan tietojasi säilytetään?",
        PolicySubHeader6 : "Mitkä ovat tietosuojaoikeutesi?",
        PolicySubHeader7 : "Markkinointi",
        PolicySubHeader8 : "Tietojenkäsittelyn oikeudellinen perusta",
        PolicySubHeader9 : "Valituskäytäntö",
        PolicySubHeader10 : "Päivitykset tietosuojakäytäntöön",
        PolicySubHeader11 : "GDPR-noudattaminen",
        PolicySubHeader12 : "Päivämäärä ja yhteystiedot",
        PolicyP1 : "Keräämme tietoja yhteydenottolomakkeen kautta ja saatamme kerätä tietoja myös automaattisesti evästeiden ja vastaavien teknologioiden avulla.",
        PolicyP2 : "Emme jaa tietojasi kolmansille osapuolille paitsi sähköpostipalveluntarjoajan kanssa, jota käytetään automaattisten sähköpostien lähettämiseen, ja varmistamme, että he noudattavat tietosuojasäädöksiä.",
        PolicyP3 : "Toteutamme asianmukaisia teknisiä ja organisatorisia toimenpiteitä suojellaksemme tietojasi luvattomalta pääsyltä, muutoksilta, paljastuksilta tai tuhoutumiselta.",
        PolicyP4 : "Tietosi voidaan siirtää ja tallentaa palvelimille, jotka sijaitsevat maasi tai alueesi ulkopuolella. Lähettämällä tietosi suostut tähän siirtoon.",
        PolicyP5 : "Säilytämme tietojasi niin kauan kuin se on tarpeen tässä käytännössä määriteltyjen tarkoitusten täyttämiseksi tai lain vaatimusten mukaisesti. Tiedot poistetaan turvallisesti säilytysajan jälkeen.",
        PolicyP6 : "Sinulla on täydet oikeudet tietoihisi. Pyynnöstäsi lähetämme, korjaamme, rajoitamme käsittelyä tai poistamme tietosi.",
        PolicyP7 : "Yhteystietojesi perusteella tarjoamme sinulle parhaiten sopivia palveluja tarpeidesi täyttämiseksi. Muita pyytämättömiä viestejä ei lähetetä sinulle tietojen antamisen seurauksena.",
        PolicyP8 : "Käsittelemme tietojasi suostumuksesi perusteella, kun lähetät ne yhteydenottolomakkeen kautta tai kun se on tarpeen sopimuksen täyttämiseksi.",
        PolicyP9 : "Jos sinulla on huolenaiheita siitä, miten käsittelemme tietojasi, ota meihin yhteyttä. Sinulla on myös oikeus tehdä valitus valvontaviranomaiselle.",
        PolicyP10 : "Voimme päivittää tätä tietosuojakäytäntöä aika ajoin. Kaikki muutokset tulevat voimaan välittömästi tarkistetun käytännön julkaisemisen jälkeen tällä sivulla.",
        PolicyP11 : "Tämä tietosuojakäytäntö noudattaa yleistä tietosuoja-asetusta (GDPR) soveltuvin osin.",
        PolicyP12 : "Tämä käytäntö päivitettiin viimeksi 8.5.2024. Jos haluat ottaa meihin yhteyttä tämän käytännön suhteen, käytä yhteydenottolomaketta tai sivuilla näkyvää sähköpostiosoitetta.",

        SentMessageHeader : "Kiitos viestistäsi!",
        SentMessageP : "Sinuun ollaan yhteydessä pian."

    },
  }

  

export default messages