import React, { useState, useEffect } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'

const ContactHeader1 = () => (
    <FormattedMessage id="ContactHeader1" />
);

const ContactHeader2 = () => (
    <FormattedMessage id="ContactHeader2" />
);

const ContactHeader3 = () => (
    <FormattedMessage id="ContactHeader3" />
);

const ContactPlaceholderFirstName = () => (
    <FormattedMessage id="ContactPlaceholderFirstName" defaultMessage="First name (required)">
        {placeholder=>  
            <Form.Control type="text" className="form-control" name="firstName" placeholder={placeholder} required/>
        }
    </FormattedMessage>
);

const ContactFeedbackFirstName = () => (
    <FormattedMessage id="ContactFeedbackFirstName" />
);

const ContactPlaceholderLastName = () => (
    <FormattedMessage id="ContactPlaceholderLastName" defaultMessage="Last name (required)">
        {placeholder=>  
            <Form.Control type="text" className="form-control" name="lastName" placeholder={placeholder} required/>
        }
    </FormattedMessage>
);

const ContactFeedbackLastName = () => (
    <FormattedMessage id="ContactFeedbackLastName" />
);

const ContactPlaceholderOrg = () => (
    <FormattedMessage id="ContactPlaceholderOrg" defaultMessage="Organization (required)">
        {placeholder=>  
            <Form.Control type="text" className="form-control" name="organization" placeholder={placeholder} required/>
        }
    </FormattedMessage>
);

const ContactFeedbackOrg = () => (
    <FormattedMessage id="ContactFeedbackOrg" />
);

const ContactPlaceholderMsg = () => (
    <FormattedMessage id="ContactPlaceholderMsg" defaultMessage="Message (required)">
        {placeholder=>  
            <textarea rows="5" className="form-control" cols="60" name="text" placeholder={placeholder} maxLength="1000" required></textarea>
        }
    </FormattedMessage>
);

const ContactFeedbackMsg = () => (
    <FormattedMessage id="ContactFeedbackMsg" />
);

const ContactPlaceholderEmail = () => (
    <FormattedMessage id="ContactPlaceholderEmail" defaultMessage="Email (required)">
        {placeholder=>  
            <Form.Control type="email" className="form-control" name="email" placeholder={placeholder} required/>
        }
    </FormattedMessage>
);

const ContactFeedbackEmail = () => (
    <FormattedMessage id="ContactFeedbackEmail" />
);

const ContactPlaceholderPhone = () => (
    <FormattedMessage id="ContactPlaceholderPhone" defaultMessage="Phone">
        {placeholder=>  
            <Form.Control type="phone" className="form-control" name="phone" placeholder={placeholder}/>
        }
    </FormattedMessage>
);

const ContactPlaceholderPolicy1 = () => (
    <FormattedMessage id="ContactPlaceholderPolicy1" />
);

const ContactPlaceholderPolicy2 = () => (
    <FormattedMessage id="ContactPlaceholderPolicy2" />
);



const Contact = () => {

    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['ContactPageTitle'];
      document.title = titleText
    },[intl.locale])

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }

        setValidated(true);
    }

    const onChange = (value) => {
        console.log('Captcha value:', value);
      }

    document.body.style.overflowY = 'overlay';
    
    return (
    <Container fluid className="Contact d-flex flex-column justify-content-center align-items-center mt-4" >
        <p id="Contact-header" className="mb-1 lead fw-normal small-screen-padding" ><ContactHeader1/></p>
        <p className="mb-1 lead fw-normal" ><ContactHeader2/></p>
        <p className="mb-4 lead fw-normal" ><ContactHeader3/></p>
        <div id="formContent" className="d-flex justify-content-center flex-wrap text-start" >
            <Form noValidate validated={validated} onSubmit={handleSubmit} method="POST" action="/send-message">

                    <Form.Group className="col mb-2" as={Col} controlId="firstName" >
                        <ContactPlaceholderFirstName/>
                        <Form.Control.Feedback type="invalid" ><ContactFeedbackFirstName/></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col mb-2" as={Col} controlId="lastName">
                        <ContactPlaceholderLastName/>
                        <Form.Control.Feedback type="invalid" ><ContactFeedbackLastName/></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col mb-2" as={Col} controlId="organization">
                        <ContactPlaceholderOrg/>
                        <Form.Control.Feedback type="invalid" ><ContactFeedbackOrg/></Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="col mb-2" as={Col} controlId="text">
                        <ContactPlaceholderMsg/>
                        <Form.Control.Feedback type="invalid" ><ContactFeedbackMsg/></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col mb-2" as={Col} controlId="email">
                        <ContactPlaceholderEmail/>
                        <Form.Control.Feedback type="invalid" ><ContactFeedbackEmail/></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col mb-2" as={Col} controlId="phone">
                    <ContactPlaceholderPhone/>
                    </Form.Group>


                    <Form.Group as={Col} controlId="policy" className="checkbox-div col mb-2">
                        <Form.Check
                            type="checkbox"
                            className="form-control"
                            name="policy"
                            label={<div><ContactPlaceholderPolicy1/><a href="#/privacy-policy"><ContactPlaceholderPolicy2/></a>*</div>}
                            required/>
                        <Form.Control.Feedback type="invalid" ></Form.Control.Feedback>
                    </Form.Group>
                    

                <ReCAPTCHA
                    sitekey="6LfTMB8iAAAAAG9MeIGvMNwvv7It78dO6bJY8n8K"
                    onChange={onChange}
                />
                <button type="submit" className="btn btn-primary mt-3  mb-2">Send</button>
            </Form>
        </div>
    </Container>
    )
}

export { Contact as default }