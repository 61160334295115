import React from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl'
import messages from './messages'

const padding = "5px"

const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);

const ShareSocial = () => <TranslatedMessage id="ShareSocial" />;
const ShareHomepageSocial = () => <TranslatedMessage id="ShareHomepageSocial" />;

const ShareOnFacebook = ({ url }) => {
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;

  return (
    <a
      href={shareUrl}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Share on Facebook"
      style={{ padding }}
    >
      <i className="fab fa-facebook-square fa-2x" ></i>
    </a>
  );
};

const ShareOnTwitter = ({ url, title }) => {
  const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`;

  return (
    <a
      href={shareUrl}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Share on Twitter"
      style={{ padding }}
    >
      <i className="fab fa-twitter-square fa-2x"></i>
    </a>
  );
};

const ShareOnLinkedIn = ({ url, title, description }) => {
  const shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`;

  return (
    <a
      href={shareUrl}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Share on LinkedIn"
      style={{ padding }}
    >
      <i className="fab fa-linkedin fa-2x"></i>
    </a>
  );
};

const ShareButtons = () => {
    const intl = useIntl();
    const locale = intl.locale;
    const titleText = messages[locale]['FeaturesPageTitle'];
    const metaDescription = messages[locale]['FeaturePageMeta'];
    const url = window.location.href;
    // const title = document.title;
    // const description = document.querySelector('meta[name="description"]')
  
    return (
      <div className="d-flex fst-italic justify-content-center align-items-center" style={{ backgroundColor : "white" }}>
        <ShareSocial/>
        <ShareOnFacebook url={url}/>
        {/* <ShareOnTwitter url={url} title={titleText} /> */}
        <ShareOnLinkedIn url={url} title={titleText} description={metaDescription}  />
      </div>
    );
  }

  const HomeShareButtons = () => {
    const intl = useIntl();
    const locale = intl.locale;
    const titleText = messages[locale]['HomePageTitle'];
    const metaDescription = messages[locale]['HomePageMeta'];
    const url = "https://www.craneoptimizer.com/";
    // const title = document.title;
    // const description = document.querySelector('meta[name="description"]')
  
    return (
      <div className="HomeShareButtons mt-2 d-flex fst-italic justify-content-center align-items-center" >
        <ShareHomepageSocial/>
        <ShareOnFacebook url={url}/>
        {/* <ShareOnTwitter url={url} title={titleText} /> */}
        <ShareOnLinkedIn url={url} title={titleText} description={metaDescription}  />
      </div>
    );
  }

export { ShareOnFacebook, ShareOnTwitter, ShareOnLinkedIn, ShareButtons, HomeShareButtons };