import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import AOS from 'aos'
import "aos/dist/aos.css"
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'

import Footer from '../Components/Footer';


const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);

const Slide1Header1 = () => <TranslatedMessage id="Slide1Header1" />
const Slide1Header2 = () => <TranslatedMessage id="Slide1Header2" />
const Slide1P1 = () => <TranslatedMessage id="Slide1P1" />
const Slide1P2 = () => <TranslatedMessage id="Slide1P2" />

const Slide2Header1 = () => <TranslatedMessage id="Slide2Header1" />
const Slide2Header2 = () => <TranslatedMessage id="Slide2Header2" />
const Slide2Header3 = () => <TranslatedMessage id="Slide2Header3" />
const Slide2P1 = () => <TranslatedMessage id="Slide2P1" />
const Slide2Li1 = () => <TranslatedMessage id="Slide2Li1" />
const Slide2Li2 = () => <TranslatedMessage id="Slide2Li2" />
const Slide2Li3 = () => <TranslatedMessage id="Slide2Li3" />

const Slide3Header1 = () => <TranslatedMessage id="Slide3Header1" />
const Slide3Header2 = () => <TranslatedMessage id="Slide3Header2" />
const Slide3Header3 = () => <TranslatedMessage id="Slide3Header3" />
const Slide3P1 = () => <TranslatedMessage id="Slide3P1" />
const Slide3Li1 = () => <TranslatedMessage id="Slide3Li1" />
const Slide3Li2 = () => <TranslatedMessage id="Slide3Li2" />

const Slide4Header1 = () => <TranslatedMessage id="Slide4Header1" />
const Slide4Header2 = () => <TranslatedMessage id="Slide4Header2" />
const Slide4Header3 = () => <TranslatedMessage id="Slide4Header3" />
const Slide4P1 = () => <TranslatedMessage id="Slide4P1" />
const Slide4Li1 = () => <TranslatedMessage id="Slide4Li1" />
const Slide4Li2 = () => <TranslatedMessage id="Slide4Li2" />
const Slide4Li3 = () => <TranslatedMessage id="Slide4Li3" />

const Slide5Header1 = () => <TranslatedMessage id="Slide5Header1" />
const Slide5Header2 = () => <TranslatedMessage id="Slide5Header2" />
const Slide5P1 = () => <TranslatedMessage id="Slide5P1" />
const Slide5Li1 = () => <TranslatedMessage id="Slide5Li1" />
const Slide5Li2 = () => <TranslatedMessage id="Slide5Li2" />
const Slide5Li3 = () => <TranslatedMessage id="Slide5Li3" />

const Slide6Header1 = () => <TranslatedMessage id="Slide6Header1" />
const Slide6Header2 = () => <TranslatedMessage id="Slide6Header2" />
const Slide6P1 = () => <TranslatedMessage id="Slide6P1" />
const Slide6Li1 = () => <TranslatedMessage id="Slide6Li1" />
const Slide6Li2 = () => <TranslatedMessage id="Slide6Li2" />
const Slide6Li3 = () => <TranslatedMessage id="Slide6Li3" />

const Slide7Header1 = () => <TranslatedMessage id="Slide7Header1" />
const Slide7Header2 = () => <TranslatedMessage id="Slide7Header2" />
const Slide7P1 = () => <TranslatedMessage id="Slide7P1" />
const Slide7Li1 = () => <TranslatedMessage id="Slide7Li1" />
const Slide7Li2 = () => <TranslatedMessage id="Slide7Li2" />
const Slide7Li3 = () => <TranslatedMessage id="Slide7Li3" />

const headersAndPics = [
    {
      header: (
          <Col className='headerContainerMobile entryHeaderContainer'>
            <h1 className="display-5 entryHeader">
              <div><Slide1Header1/><span className="text-primary"><Slide1Header2/></span>.</div>
              
            </h1>
            <p className="lead pt-3 text-center entryText"><Slide1P1/><br/><Slide1P2/></p>
          </Col>
      ),
      picturePath: '/videos/main_pic.png',
    },
    {
      header: (
          <Col className='headerContainerMobile'>
            <h1 className="display-5">
              <div><Slide2Header1/><span className="text-primary"><Slide2Header2/></span><Slide2Header3/></div>
              
            </h1>
            <p className="pt-0 pb-0 text-center"><Slide2P1/></p>
            <ul className="text-start headerList pt-0">
              <li><Slide2Li1/></li>
              <li><Slide2Li2/></li>
              <li><Slide2Li3/></li>
            </ul>
          </Col>
      ),
      picturePath: '/videos/find-best-locations-for-cranes-mobile.png',
    },
    {
      header: (
        <Col className='headerContainerMobile'>
        <h1 className="display-5">
          <div><Slide3Header1/><span className="text-primary"><Slide3Header2/></span><Slide3Header3/></div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide3P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide3Li1/></li>
          <li><Slide3Li2/></li>
          <br></br>
        </ul>
      </Col>
      ),
      picturePath: '/videos/compare-best-locations-for-cranes-mobile.png',
    },
    {
      header: (
        <Col className='headerContainerMobile'>
        <h1 className="display-5">
          <div><Slide4Header1/><span className="text-primary"><Slide4Header2/></span><Slide4Header3/></div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide4P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide4Li1/></li>
          <li><Slide4Li2/></li>
          <li><Slide4Li3/></li>
        </ul>
      </Col>
      ),
      picturePath: '/videos/find-the-right-cranes-mobile.png',
    },
  {
      header: (
        <Col className='headerContainerMobile'>
        <h1 className="display-5">
          <div><Slide5Header1/><span className="text-primary"><Slide5Header2/></span>.</div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide5P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide5Li1/></li>
          <li><Slide5Li2/></li>
          <li><Slide5Li3/></li>
        </ul>
      </Col>
      ),
      picturePath: '/videos/optimize-structural-design-mobile.png',
    },
  {
      header: (
        <Col className='headerContainerMobile'>
        <h1 className="display-5">
          <div><Slide6Header1/><span className="text-primary"><Slide6Header2/></span>.</div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide6P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide6Li1/></li>
          <li><Slide6Li2/></li>
          <li><Slide6Li3/></li>
          <br></br>
        </ul>
      </Col>
      ),
      picturePath: '/videos/show-crane-manufacturer-data-mobile.png',
    },
  {
      header: (
        <Col className='headerContainerMobile'>
        <h1 className="display-5">
          <div><Slide7Header1/><span className="text-primary"><Slide7Header2/></span>.</div>
          
        </h1>
        <p className="pt-0 pb-0 text-center"><Slide7P1/></p>
        <ul className="text-start headerList pt-0">
          <li><Slide7Li1/></li>
          <li><Slide7Li2/></li>
          <li><Slide7Li3/></li>
        </ul>
      </Col>
      ),
      picturePath: '/videos/bim-dashboard-online-app-mobile.png',
    }
    
];

const SlidePicture = (src) => {
  const path = src.src
  
  return (
    <div className='SlidePicture' >
      <img src={path} alt="crane-optimization"></img>
    </div>
  )
}

const HomeMobile = () => {
    document.body.style.overflowY = 'auto';
    const [isPortraitMode, setIsPortraitMode] = useState(false);
    
    const [activeIndex, setActiveIndex] = useState(0);
    const containerRef = useRef(null);
    const headerRefs = useRef([]);
    
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['HomePageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['HomePageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
      
    },[intl.locale])

    

    useEffect(() => {
      const handleMatchMediaChange = (e) => {
        const isPortrait = e.matches;
        setIsPortraitMode(isPortrait);
      };
  
      const mediaQuery = window.matchMedia('(orientation: portrait) and (max-aspect-ratio: 9/16)');
      mediaQuery.addEventListener('change', handleMatchMediaChange);
      handleMatchMediaChange(mediaQuery);
  
      return () => {
        mediaQuery.removeEventListener('change', handleMatchMediaChange);
      };
    }, [isPortraitMode]);

    const observerCallback = useCallback(
      (entries) => {
        entries.forEach((entry) => {
          const element = entry.target;
          if (entry.isIntersecting) {
            element.classList.add('aos-animate');
            AOS.init({
              once: false,
              duration: 1500,
            });
          } else {
            element.classList.remove('aos-animate');
          }
        });
      },
      []
    );
    
    useEffect(() => {
      const observer = new IntersectionObserver(observerCallback, {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      });

      const currentRefs = headerRefs.current;

      currentRefs.forEach((ref) => {
        if (ref) {
          observer.observe(ref);
        }
      });

      return () => {
        currentRefs.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref);
          }
        });
      }
    }, [observerCallback]);

    // useEffect(() => {
    //   const observer = new IntersectionObserver(
    //     (entries) => {
    //       entries.forEach((entry) => {
    //         const element = entry.target;
    //         if (entry.isIntersecting) {
    //           element.classList.add('aos-animate');
    //           AOS.init({
    //             once: false,
    //             duration:1500
    //           });
    //         }
    //         else {
    //           element.classList.remove('aos-animate');
    //         }
    //       });
    //     },
    //     {
    //       root: null,
    //       rootMargin: '0px',
    //       threshold: 0.9, // Adjust this value to control when the animation should trigger
    //     }
    //   );
    
    //   const currentRefs = headerRefs.current;

    //   currentRefs.forEach((ref) => {
    //     if (ref) {
    //       observer.observe(ref);
    //     }
    //   });

    //   return () => {
    //     currentRefs.forEach((ref) => {
    //       if (ref) {
    //         observer.unobserve(ref);
    //       }
    //     });
    //   }
    // }, []);

    return (
      <div>
        <Container className="Home d-flex justify-content-center" >
            <Row className="align-items-center justify-content-center videoRow">
                <div className='pictureAndheaderContainerMobile d-flex flex-column' ref={containerRef} style={{padding: '0' }}>
                {headersAndPics.map(({ header, picturePath }, index) => (
                        <div
                            id={index}
                            key={index}
                            ref={(el) => (headerRefs.current[index] = el)}
                            style={{ 
                            }}
                            className='pictureAndheaderSubContainer d-flex flex-col justify-content-center'
                            data-aos="fade"
                            data-aos-offset="100"
                        >
                            
                              <SlidePicture src={picturePath}/>
                              {header}
                            
                            
                        </div>
                        ))
                    }
                    <Footer/>
                </div>
                
            </Row>
        </Container>
        </div>
    )
}

export { HomeMobile as default }