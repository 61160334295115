import './App.scss';

import AppRouter from './Router/AppRouter';

const App = () => {

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
