import React, {useEffect} from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl';
import messages from './messages'

const TranslatedMessage = ({ id }) => (
    <FormattedMessage id={id} />
);
const SentMessageHeader = () => <TranslatedMessage id="SentMessageHeader" />
const SentMessageP = () => <TranslatedMessage id="SentMessageP" />

const SentMessage = () => {
    const intl = useIntl();
    useEffect(() => {
      const locale = intl.locale;
      const titleText = messages[locale]['SentMessagePageTitle'];
      document.title = titleText

      const metaDescription = messages[locale]['SentMessagePageMeta'];
      const existingMetaTag = document.head.querySelector(`meta[name="description"]`)
      if (existingMetaTag) {
        existingMetaTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', "description");
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    },[intl.locale])
    return (
        <Container>
            <h4 className="lead" ><SentMessageHeader/><br/><SentMessageP/></h4>
        </Container>
    )
}

export default SentMessage