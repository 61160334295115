import React, { useEffect, useState} from 'react'
import { Container, Nav, Col, Image, Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'

const Template = () => (
    <FormattedMessage id="" />
);

const Home = () => (
    <FormattedMessage id="navHome" />
);
const Features = () => (
    <FormattedMessage id="navFeatures" />
);
const Contact = () => (
    <FormattedMessage id="navContact" />
);

const BSNavbar = () => {
    return (
    <Container fluid className="Navbar" >
        <Nav className="justify-content-between mb-0 d-flex w-100" activeKey="/home">
            <Col className="text-start">
                <Nav.Item>
                    <Image className="NavLogo" src="/craneoptimizer-company-logo.png"></Image>
                </Nav.Item>
            </Col>
            <Col className="d-flex justify-content-center NavItems">
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/"><Home/></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/features"><Features/></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} to="/contact"><Contact/></Nav.Link>
                </Nav.Item>
            </Col>
            <Col className=""></Col>
        </Nav>
    </Container>
    )
}

export { BSNavbar as default }